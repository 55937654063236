<template>
  <div>
    <v-toolbar dense flat class="mb-5">
      <div class="text-h5 red--text" style="width: 180px">ใบเพิ่มหนี้</div>
      <div style="width: 330px">
        <v-text-field
          class="mr-2"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ชื่อลูกค้า ทะเบียน ชื่อโปรเจ็ค เบอร์โทร"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup.enter="handleEnter"
        ></v-text-field>
      </div>

      <v-spacer></v-spacer>

      <v-row class="mt-2">
        <v-checkbox
          v-model="toDay"
          @change="changeToday(toDay)"
          label="เฉพาะวันนี้"
        ></v-checkbox>
      </v-row>
      <div style="width: 140px">
        <DatePicker
          v-if="toDay == false"
          label="วันเริ่มต้น"
          :value="filter.start_date"
          @onChange="
            (val) => {
              filter.start_date = val;
              getData(true);
            }
          "
          hideDetails
        />
      </div>
      <div style="width: 140px">
        <DatePicker
          v-if="toDay == false"
          label="วันสิ้นสุด"
          :value="filter.end_date"
          @onChange="
            (val) => {
              filter.end_date = val;
              getData(true);
            }
          "
          hideDetails
        />
      </div>

      <v-spacer></v-spacer>

      <div style="width: 150px">
        <v-select
          label="สถานะ"
          :items="status"
          item-value="value"
          item-text="text"
          outlined
          dense
          hide-details
          v-model="filter.status"
          @change="getData(true)"
        />
      </div>

      <v-spacer></v-spacer>

      <!-- <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มรายการ</v-btn
      > -->
    </v-toolbar>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @click:row="edit"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <menu-ship-document
          small
          v-model="item.status"
          :id="item._id"
          :statusId="item.status"
          @success="getData()"
          :docTypeId="docTypeId"
        />
      </template>
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>
      <template v-slot:[`item.due_date`]="{ item }">
        {{ formatDate(item.due_date) }}
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0.00') }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="print(item)">
                <v-list-item-title>พิมพ์</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="dialogResetData = {
                  document_id: item._id,
                  note: '',
                }, dialogReset = true">
                <v-list-item-title>รีเซ็ท</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
    
    <v-dialog persistent scrollable max-width="500" v-model="dialogReset">
      <DocumentResetWithNote
        :item="dialogResetData"
        @onClose="dialogReset = false"
        @onSubmit="onSubmitReset"
      />
    </v-dialog>
    
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';
import DocumentResetWithNote from '@/components/DocumentResetWithNote.vue';
import * as moment from 'moment';

export default {
  components: {
    DatePicker,
    DocumentResetWithNote,
  },
  data: () => ({
    toDay: false,
    docTypeId: 4,
    loading: true,
    awaitingSearch: false,
    pageCount: 0,
    list: [],
    menu: false,
    dialogReset: false,
    dialogResetData: {
      document_id: '',
      note: '',
    },
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 0},
      { text: 'วันที่', value: 'date_of_issue', width: 0 },
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อลูกค้า', value: 'customer_name' },
      { text: 'ชื่อโปรเจ็ค', value: 'project_name', filterable: false },
      { text: 'วันครบกำหนด', value: 'due_date', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      {
        text: 'สถานะ',
        value: 'status',
        sortable: false,
        filterable: false,
        align: 'center',
      },
      { text: '', value: 'actions', sortable: false, filterable: false },
    ],
    status: [
      { text: 'สถานะ', value: '' },
      { text: 'รอดำเนินการ', value: 0 },
      { text: 'เก็บเงินแล้ว', value: 1 },
      { text: 'ยกเลิก', value: 2 },
    ],
    filter: {
      branch_id: 0,
      document_type: 4, //4
      status: '',
      item_per_page: 20,
      page: 1,
      start_with: '',
      start_date: moment().add(-3, 'month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
  }),
  created() {
    this.filter.document_type = this.docTypeId;
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
  },

  methods: {
    changeToday(val) {
      if (val == true) {
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = moment().format('YYYY-MM-DD');
      } else {
        this.filter.start_date = moment().add(-3, 'month').format('YYYY-MM-DD');
        this.filter.end_date = moment().format('YYYY-MM-DD');
      }
      this.getData();
    },
    add() {
      this.$router.push({ name: 'document-credit-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'document-credit-update',
        params: { id: item._id },
      });
    },
    async reset() {
      let body = {
        token: this.$jwt.sign(
          { 
            document_id: this.dialogResetData.document_id,
            note: this.dialogResetData.note,
          },
          this.$privateKey,
          { noTimestamp: true, }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/reset_document`, body)
        .then((res) => {
          if (res.status == 'overdue')
            this.$alertServerError({
              title:
                'เกินระยะเวลารีเช็ต หากต้องการรีเช็ต โปรดใช้บัญชี เจ้าของ',
            });
          this.getData();
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    download(item) {
      this.$router.push({
        name: 'document-credit-update',
        params: { id: item._id },
        query: { download: true },
      });
    },
    print(item) {
      this.$router.push({
        name: 'document-credit-update',
        params: { id: item._id },
        query: { print: true },
      });
    },
    async getData(reload = false) {
      try {
        if (reload) {
          this.onChangeitemsPerPage(this.filter.item_per_page);
          this.filter.page = 1;
        }
        this.filter.end_date = moment(this.filter.end_date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        this.loading = true;
        let body = {
          token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
        };
        const res = await this.$axios.post(`${this.$baseUrl}/document/get_document`, body);
        this.list = res.result.data;
        this.pageCount = res.result.total_page || 1;
      } catch (err) {
        console.error('Error fetching data:', err);
        this.$alertServerError({ title: err.error_message });
      } finally {
        this.loading = false;
      }
    },
    onChangePage(page) {
      if (this.filter.page !== page) {
        this.filter.page = page;
        this.getData();
      }
    },
    onChangeitemsPerPage(number) {
      if (this.filter.item_per_page !== number) {
        this.filter.item_per_page = number;
        this.filter.page = 1;
        this.getData();
      }
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    onSubmitReset(val) {
      this.dialogReset = false;
      this.dialogResetData = {
        document_id: val.document_id,
        note: val.note,
      }
      this.menu = false
      this.reset();
    },
    handleEnter() {
      this.filter.page = 1;
      this.filter.start_date = moment().add(-1, 'years').format('YYYY-MM-DD');
      this.getData(true);
    },
  },
  watch: {
    // 'filter.start_with': function (val) {
    //   this.filter.start_date = moment().add(-1, 'years').format('YYYY-MM-DD');
    //   if (!this.awaitingSearch) {
    //     setTimeout(() => {
    //       this.getData(true);
    //       this.awaitingSearch = false;
    //     }, 1000); // 1 sec delay
    //   }
    //   this.awaitingSearch = true;
    // },
        'filter.start_with': function (val) {
      // Check if val is empty, then call getData(true)
      if (val === "") {
        this.filter.start_date = moment().add(-3, 'month').format('YYYY-MM-DD');
        this.filter.page = 1;
        this.getData(true);
      }
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
